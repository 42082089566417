/* eslint-disable react/jsx-no-target-blank */
import { ErrorMessage, Formik } from "formik";
import React from "react";
import { useState } from "react";
import { FaUserAlt, FaKey } from "react-icons/fa";
import logoImg from "../assets/images/juste_singin_logo.png";
import ForgotPassword from "./ForgotPassword";
import * as Yup from "yup";
import { getProfile, login } from "../services/services.profile";
import { message } from "antd";

const Signin = ({ setModal, modal, isClose }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState(null);

  return (
    <>
      <ForgotPassword
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setModal={setModal}
      />
      {modal ? (
        <>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Please enter valid email")
                .required("Please enter your email"),
              password: Yup.string().required("Please enter your password"),
            })}
            onSubmit={async (values) => {
              localStorage.clear();
              let email = values.email;
              let password = values.password;
              const user = await login(email, password);
              if (user.status === 200) {
                message.success(`Welcome back ${user?.name}`);
                window.location.href = "/";
                setModal(false);
              } else if (user?.error) {
                message.error(user?.error?.message);
                // alert("wrong details");
              } else {
                getProfile()
                  .then((user) => {
                    setUser(user);
                  })
                  .catch((err) => {});
              }
            }}
          >
            {({ handleSubmit, handleChange, handleBlur }) => (
              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div
                  className="fixed inset-0 w-full h-full bg-black opacity-40"
                  onClick={() => setModal(false)}
                ></div>
                <div className="flex flex-col justify-center items-center min-h-screen bg-[#00000073] py-8">
                  <div className="relative w-full max-w-[30rem] mx-auto bg-white rounded-md shadow-lg">
                    <div className="flex justify-center items-center rounded-t-md text-white font-bold p-6">
                      <img src={logoImg} alt="" className=" w-[164px] " />
                    </div>
                    <div className="w-full flex items-center  px-8 pt-4 pb-10 text-base ">
                      <form onSubmit={handleSubmit}>
                        <div className="flex justify-between items-center">
                          <div>Sign in to your JUSTE account</div>
                        </div>

                        <div className="mb-4">
                          <div className="my-[10px] flex justify-center border-none text outline-none items-center !mb-[6px] bg-[#00000013]">
                            <div className="bg-[#e9ecef] border border-solid border-[#ced4da] p-3">
                              <FaUserAlt />
                            </div>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Enter email"
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="email"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <div className="mb-4">
                          <div className="my-[10px] flex justify-center border-none outline-none items-center !mb-[6px] bg-[#00000013]">
                            <div className="bg-[#e9ecef] border border-solid border-[#ced4da] p-3">
                              <FaKey />
                            </div>
                            <input
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Enter Password or PIN"
                              className="border-none outline-none focus:outline-none "
                              onBlur={handleBlur}
                              onChange={handleChange}
                            />
                          </div>
                          <ErrorMessage
                            component="div"
                            name="password"
                            className="text-red-500 text-sm"
                          />
                        </div>
                        <button
                          type="submit"
                          className="text-lg  border border-solid border-[#000] h-12 px-5 hover:text-white hover:bg-[#000]"
                        >
                          Sign in
                        </button>
                      </form>
                    </div>
                    <hr />
                    <div className="flex justify-end items-center p-4">
                      <ul>
                        <li className="py-2">
                          <a
                            href="https://onboarding.juste.ai/#/"
                            target="_blank"
                            className="hover:cursor-pointer hover:!text-[#181A1C] "
                          >
                            Join now
                          </a>
                        </li>
                        <li
                          className="hover:cursor-pointer py-2"
                          onClick={() => {
                            setIsOpen(true);
                            setModal(false);
                          }}
                        >
                          Forgot Password
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Formik>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Signin;
