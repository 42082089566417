/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import "../css/login.css";
import "../css/default.css";
import justeLogo from "../assets/images/juste_logo_new.png";
import team_features from "../assets/images/team_features-img-1.png";
import compare_img01 from "../assets/images/compare_img01.jpg";
import compare_img02 from "../assets/images/compare_img02.jpg";
import about_img from "../assets/images/about_img.jpg";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { Drawer, message } from "antd";
import { Formik } from "formik";
import { baseUrl } from "../services/services.profile";
import axios from "axios";
import * as Yup from "yup";
import VideoModal from "../components/modal/VideoModal";
import AOS from "aos";
import "aos/dist/aos.css";
import Signin from "./Signin";
import {
  ArrowUpOutlined,
  DownOutlined,
  MenuOutlined,
  RightOutlined,
  CloseOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  InstagramOutlined,
  LinkedinFilled,
  TikTokOutlined,
  YoutubeFilled,
  FacebookFilled,
  TwitterOutlined,
} from "@ant-design/icons";

const Login = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isHeader, setIsHeader] = useState(false);
  const [sideBar, setSideBar] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timeoutId;

    const toggleVisibility = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setVisible(window.scrollY > 250);
      }, 100);
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    AOS.init();
  }, []);

  const contactApi = async (email, name, mess) => {
    const res = await axios
      .post(baseUrl + "/api/subscribedEmail", {
        email,
        name,
        mess,
      })
      .then((res) => {
        console.log(res, "resss");
        message.success(res?.data?.msg);
      })
      .catch((err) => {
        message.error(res?.data?.msg);
      });
  };

  const changeNavbar = () => {
    if (window.scrollY >= 20) {
      setIsHeader(true);
    } else {
      setIsHeader(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);

  useEffect(() => {
    if (isOpen === true || modal === true || sideBar === true) {
      document.body.style.overflow = "hidden";
    } else {
      if (document.body.style.overflow === "hidden") {
        document.body.style.overflow = "visible";
      }
    }
  }, [isOpen, modal, sideBar]);

  const HeaderList = ({ isInDrawer }) => {
    const commonClasses =
      "text-[16px] font-bold leading-none tracking-[0.005em] whitespace-nowrap";
    const drawerClasses =
      "flex flex-col list-none p-0 cursor-pointer text-[#160042]";
    const headerClasses =
      "flex list-none bg-[#F1F3FA] rounded-full px-5 cursor-pointer";

    const liClasses = isInDrawer
      ? "py-[10px] pr-[60px] pl-[25px]"
      : "px-[6px] lg:px-3 py-3 rounded";

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
      setDropdownOpen((prev) => !prev);
    };

    const closeDropdown = () => {
      setDropdownOpen(false);
    };

    return (
      <ul
        className={isInDrawer ? drawerClasses : headerClasses}
        style={{ borderTop: isInDrawer ? "1px solid #0000001a" : "" }}
      >
        <li
          className={liClasses}
          style={{ borderBottom: isInDrawer ? "1px solid #0000001a" : "" }}
        >
          <span className={commonClasses}>Home</span>
        </li>
        <li
          className={liClasses}
          style={{
            padding: isInDrawer ? "4px 0px" : "",
            borderBottom: isInDrawer ? "1px solid #0000001a" : "",
          }}
          onMouseEnter={!isInDrawer ? toggleDropdown : undefined}
          onMouseLeave={!isInDrawer ? closeDropdown : undefined}
        >
          {isInDrawer ? (
            <>
              <div className="flex flex-row justify-between items-center px-[25px]">
                <span className={commonClasses}>Products</span>
                <div
                  className="w-8 h-8 text-white bg-[#6721ff] rounded flex justify-center"
                  onClick={toggleDropdown}
                >
                  <DownOutlined />
                </div>
              </div>
            </>
          ) : (
            <>
              <span className={commonClasses}>
                Products
                <DownOutlined className="ml-1" />
              </span>
            </>
          )}

          <ul
            className={`duration-500 ease-in-out overflow-hidden ${
              dropdownOpen ? "max-h-[200px] py-4" : "max-h-0"
            } list-none ${
              isInDrawer
                ? "pt-1 p-0"
                : "absolute p-0 mt-3 bg-[#160042] text-white rounded-[5px] w-[220px] z-10"
            }`}
          >
            <li
              className={`cursor-pointer font-outfit font-semibold leading-[1.4] text-[16px] ${
                isInDrawer ? "pl-10 pr-14 py-3" : "block hover:bg-[#6721ff] p-4"
              }`}
              style={{
                borderBottom: isInDrawer ? "1px solid #0000001a" : "",
              }}
            >
              <span>Anti Money Laundering (AML) LIVE</span>
            </li>
            <li
              className={`cursor-pointer font-outfit font-semibold leading-[1.4] text-[16px] ${
                isInDrawer ? "pl-10 pr-14 py-3" : "block hover:bg-[#6721ff] p-4"
              }`}
              style={{
                borderBottom: isInDrawer ? "1px solid #0000001a" : "",
              }}
            >
              <span>GDPR (Coming soon)</span>
            </li>
            <li
              className={`cursor-pointer font-outfit font-semibold leading-[1.4] text-[16px] ${
                isInDrawer ? "pl-10 pr-14 py-3" : "block hover:bg-[#6721ff] p-4"
              }`}
            >
              <span>EU AI Act (Coming soon)</span>
            </li>
          </ul>
        </li>
        <li
          className={liClasses}
          style={{ borderBottom: isInDrawer ? "1px solid #0000001a" : "" }}
        >
          <span className={commonClasses}>How Does it Work?</span>
        </li>
        <li
          className={liClasses}
          style={{ borderBottom: isInDrawer ? "1px solid #0000001a" : "" }}
        >
          <span className={commonClasses}>Pricing</span>
        </li>
        <li
          className={liClasses}
          style={{ borderBottom: isInDrawer ? "1px solid #0000001a" : "" }}
        >
          <span className={commonClasses}>Contact</span>
        </li>
        <li
          className={liClasses}
          style={{ borderBottom: isInDrawer ? "1px solid #0000001a" : "" }}
        >
          <span
            className={commonClasses}
            onClick={() => {
              setModal(true);
              setSideBar(false);
            }}
          >
            Sign In
          </span>
        </li>
      </ul>
    );
  };

  const FooterLink = ({ title }) => {
    return (
      <a href="#" className="group transition-all duration-300 ease-in-out">
        <span className="text-[#474C56] relative inline-block group-hover:text-[#6721ff]">
          <span className="absolute right-0 bottom-0 h-[1px] bg-gradient-to-l from-[#6721ff] to-[#6721ff] w-full origin-right scale-x-0 transition-transform duration-500 ease-out group-hover:scale-x-100 group-hover:origin-left" />
          {title}
        </span>
      </a>
    );
  };

  return (
    <div>
      <Signin
        modal={modal}
        setModal={setModal}
        isClose={() => setModal(false)}
      />
      <VideoModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Formik
        initialValues={{
          email: "",
          message: "",
          name: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Please enter valid email")
            .required("Please enter your email"),
          message: Yup.string().required("Please enter your message"),
          name: Yup.string().required("Please enter your name"),
        })}
        onSubmit={(values) => {
          let email = values.email;
          let mess = values.message;
          let name = values.name;
          contactApi(email, name, mess);
        }}
      >
        <div className="bg-white">
          {/* top ribbin */}
          <div className="flex justify-center bg-header-gradient px-3 pb-[32px]">
            <span className="font-medium text-[14px] text-white text-center leading-[23px] font-outfit !mt-2">
             iPass Pro is now known as JUSTE.
              <span className="mx-2 cursor-pointer hover:text-[#00CBFF] transition-all duration-300 ease-out whitespace-nowrap">
                Learn more
                <RightOutlined className="text-[12px] ml-[5px]" />
              </span>
            </span>
          </div>
          <div className="bg-white rounded-tr-[25px] rounded-tl-[25px] relative -top-[25px] h-[25px]"></div>

          {/* header */}
          <div className="sm:max-w-[85%] mx-auto">
            <div className="flex justify-between bg-white p-4 rounded-tr-[25px] rounded-tl-[25px] -mt-10">
              <div className="w-[116px]">
                <img src={justeLogo} alt="Logo" />
              </div>

              <Drawer
                placement="right"
                onClose={() => setSideBar(false)}
                open={sideBar}
                closable={false}
                width={300}
                bodyStyle={{ padding: 0 }}
              >
                <div className="flex justify-between py-[30px] px-[25px]">
                  <div className="w-[116px]">
                    <img src={justeLogo} alt="Logo" />
                  </div>
                  <span className="">
                    <CloseOutlined
                      className="text-[#6721ff] animate-rotate-360"
                      onClick={() => setSideBar(false)}
                    />
                  </span>
                </div>
                <HeaderList isInDrawer={true} />
                <div className="">
                  <ul className="list-none flex gap-4 py-[30px] px-5">
                    <li>
                      <a
                        href="#"
                        className="w-10 h-10 flex items-center justify-center rounded-[3px] hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                        style={{ border: "1px solid #F1F3FA" }}
                      >
                        <FacebookFilled />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-10 h-10 flex items-center justify-center rounded-[3px] hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                        style={{ border: "1px solid #F1F3FA" }}
                      >
                        <TwitterOutlined />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-10 h-10 flex items-center justify-center rounded-[3px] hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                        style={{ border: "1px solid #F1F3FA" }}
                      >
                        <InstagramOutlined />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-10 h-10 flex items-center justify-center rounded-[3px] hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                        style={{ border: "1px solid #F1F3FA" }}
                      >
                        <LinkedinFilled />
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        className="w-10 h-10 flex items-center justify-center rounded-[3px] hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                        style={{ border: "1px solid #F1F3FA" }}
                      >
                        <YoutubeFilled />
                      </a>
                    </li>
                  </ul>
                </div>
              </Drawer>

              <div className="my-auto hidden lgs:block">
                <HeaderList isInDrawer={false} />
              </div>

              <div className="lgs:hidden">
                <div
                  className="py-[5px] px-[10px] border-2 border-[#6721FF] cursor-pointer rounded"
                  onClick={() => setSideBar(true)}
                >
                  <MenuOutlined className="text-[#6721FF] font-black text-[27px]" />
                </div>
              </div>

              <div className="my-auto hidden lgs:block">
                <button
                  className="relative inline-flex items-center justify-center bg-[#160042] text-[16px] py-[10px] px-[26px] text-white rounded-full overflow-hidden gap-3 whitespace-nowrap transition-all duration-300 group"
                  onClick={() =>
                    window.open("https://onboarding.juste.ai/#/", "_blank")
                  }
                >
                  <span className="relative z-[1]">SIGN UP</span>
                  <span className="relative z-[1]">
                    <ArrowUpOutlined className="transform rotate-45 transition-transform duration-300 group-hover:rotate-90" />
                  </span>
                  <span className="absolute inset-0 bg-[#6721ff] transform translate-y-full transition-transform duration-700 ease-in-out group-hover:translate-y-0 rounded-full"></span>
                </button>
              </div>
            </div>
          </div>
          <div className="border-b border-[#F1F3FA]"></div>

          {/* main */}
          <div className="flex justify-center flex-col items-center pt-[70px] pb-[80px] px-[15px] sm:max-w-[85%] mx-auto">
            <h2 className="text-center text-[45px] md:text-[52px] lgs:text-[60px] xl:text-[72px] leading-[1.2] font-bold text-[#160042] font-plusjakartasans mb-[35px] fade-in-up">
              AML Compliance <br />
              by Gen AI Agents
            </h2>
            <p className="text-center text-[18px] leading-[1.75] font-normal text-[#474C56] font-outfit mb-[35px] fade-in-up">
              JUSTE offers a B2B SaaS platform that structures end-to-end regulatory AML compliance using AI agents,
              <br className="hidden lgs:block" /> helping businesses cut costs, reduce risks, and increase efficiency.
            </p>
          </div>

          {/* img */}
          <div className="px-[15px] sm:max-w-[85%] mx-auto mb-20">
            <div className="">
              <img
                src={team_features}
                alt="team feature"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  border: "1px solid transparent",
                  borderRadius: "6px",
                }}
              />
            </div>
          </div>

          <div className="pt-[60px] pb-[100px] relative">
            <div className="absolute top-0 left-0 w-full h-2/3 bg-[#f1f3fa] z-0"></div>
            <div className="sm:max-w-[70%] lgs:max-w-[85%] 2xl:max-w-[70%] relative mx-auto">
              <div className="flex justify-center flex-col items-center">
                <h2 className="text-center text-[36px] sm:text-[40px] md:text-[42px] lgs:text-[45px] leading-[1.2] font-bold text-[#160042] font-plusjakartasans px-[15px] mb-[60px]">
                  End-to-end AML Administration, <br />
                  Now Without the Stress and Anxiety
                </h2>
              </div>

              {/* Card Section */}
              <div className="lgs:flex lgs:gap-[30px] px-[15px]">
                <div className="rounded-[25px] overflow-hidden shadow-[0px_4px_4px_rgba(181,188,213,0.25)] mb-10 lgs:mb-0 bg-white">
                  <div className="">
                    <img
                      src={compare_img01}
                      alt="Card-1"
                      className="min-h-[260px] object-cover"
                    />
                  </div>
                  <div className="py-[25px] px-[20px]">
                    <h3 className="text-[24px] leading-[1.2] font-bold text-[#160042] font-plusjakartasans mb-[25px] mt-0">
                      Common Issues in Compliance for Regulated Entities
                    </h3>
                    <div className="">
                      <ul className="list-none p-0">
                        <li className="mb-[15px] flex">
                          <span>
                            <CloseCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#160042]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Lack of profound compliance knowledge and expertise
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CloseCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#160042]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Mostly basic training and no risk assessments
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CloseCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#160042]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Often failing audits or investigations
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CloseCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#160042]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Resulting in reprimands after audits
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CloseCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#160042]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Liable to hefty fines from unresolved issues
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="rounded-[25px] overflow-hidden shadow-[0px_4px_4px_rgba(181,188,213,0.25)] bg-white">
                  <div className="">
                    <img
                      src={compare_img02}
                      alt="Card-1"
                      className="min-h-[260px] object-cover"
                    />
                  </div>
                  <div className="py-[25px] px-[20px]">
                    <h3 className="text-[24px] leading-[1.2] font-bold text-[#160042] font-plusjakartasans mb-[25px] mt-0">
                      80% Faster and More Accurate with JUSTE's AI Agents
                    </h3>
                    <div className="">
                      <ul className="list-none p-0">
                        <li className="mb-[15px] flex">
                          <span>
                            <CheckCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#6721ff]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            AI automated with complete human oversight and
                            insight
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CheckCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#6721ff]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Structured compliance paths with real-time
                            monitoring
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CheckCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#6721ff]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            100% compliant with supervising authorities
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CheckCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#6721ff]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Multilingual Gen-AI risk assessment compliance
                            reports
                          </span>
                        </li>
                        <li className="mb-[15px] flex">
                          <span>
                            <CheckCircleFilled className="inline-block mt-[5px] mr-2 text-[18px] text-[#6721ff]" />
                          </span>
                          <span className="text-[16px] leading-[1.75] font-normal text-[#474C56] font-outfit">
                            Private cloud servers ensure AI-data privacy
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* vedio section */}
          <div className="lgs:flex lgs:justify-between px-[15px] sm:max-w-[85%] 2xl:max-w-[70%] mx-auto">
            <div className="lgs:order-2 lgs:w-[60%] relative">
              <img
                src={about_img}
                alt="About"
                className="lgd:max-w-[700px] rounded-[25px]"
              />
              <FaPlay
                className="w-[70px] h-[70px] hover:w-20 hover:h-20 absolute text-white bottom-[40%] right-0 left-[50%] cursor-pointer"
                onClick={() => setIsOpen(true)}
              />
            </div>
            <div className="lgs:order-1 lgs:w-[40%]">
              <h2 className="text-center lgs:text-left text-[36px] sm:text-[40] md:text-[42px] lgs:text-[45px] leading-[1.2] font-bold text-[#160042] font-plusjakartasans">
                JUSTE <br />
                in 3 minutes
              </h2>
            </div>
          </div>

          {/* bottom section */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 mt-24 mb-7 px-[15px] sm:max-w-[85%] 2xl:max-w-[70%] mx-auto">
            {/* company */}
            <div className="">
              <h4 className="text-[14px] font-plusjakartasans leading-[1.2] font-bold tracking-wider mb-8 mt-0 text-[#160042]">
                COMPANY
              </h4>
              <div className="">
                <ul className="list-none p-0">
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="About Us" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Pricing" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Jobs" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Press Kit" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Contact Sales" />
                  </li>
                </ul>
              </div>
            </div>

            {/* Products */}
            <div className="">
              <h4 className="text-[14px] font-plusjakartasans leading-[1.2] font-bold tracking-wider mb-8 mt-0 text-[#160042]">
                PRODUCTS
              </h4>
              <div className="">
                <ul className="list-none p-0">
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="AML Compliance (LIVE)" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="KYB Pro (LIVE)" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="iPass ID (LIVE)" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="GDPR Compliance (SOON)" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="AI Compliance (SOON)" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="NIS2 Compliance (SOON)" />
                  </li>
                </ul>
              </div>
            </div>

            {/* Resources */}
            <div className="">
              <h4 className="text-[14px] font-plusjakartasans leading-[1.2] font-bold tracking-wider mb-8 mt-0 text-[#160042]">
                RESOURCES
              </h4>
              <div className="">
                <ul className="list-none p-0">
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="How Does It Work?" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Book a Demo" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="FAQs" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Example videos" />
                  </li>
                  <li className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px]">
                    <FooterLink title="Case Studies" />
                  </li>
                </ul>
              </div>
            </div>

            {/* Contact us */}
            <div className="">
              <h4 className="text-[14px] font-plusjakartasans leading-[1.2] font-bold tracking-wider mb-8 mt-0 text-[#160042]">
                CONTACT US
              </h4>
              <div className="flex flex-col mb-[25px]">
                <a
                  href="tel:0123456789"
                  className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px] hover:text-[#6721ff]"
                >
                  +(44) 77 2740 7318
                </a>
                <a
                  href="mailto:hello@juste.ai"
                  className="text-[16px] font-outfit font-normal text-[#474C56] mb-[11px] hover:text-[#6721ff]"
                  style={{ textDecoration: "underline" }}
                >
                  hello@juste.ai
                </a>
              </div>
              <div className="">
                <ul className="list-none flex gap-4 p-0">
                  <li>
                    <a
                      href="#"
                      className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                      style={{ border: "1px solid #F1F3FA" }}
                    >
                      <TikTokOutlined />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                      style={{ border: "1px solid #F1F3FA" }}
                    >
                      <LinkedinFilled />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="w-10 h-10 flex items-center justify-center rounded-full hover:bg-[#6721ff] hover:text-white transition-all duration-300 ease-in-out"
                      style={{ border: "1px solid #F1F3FA" }}
                    >
                      <InstagramOutlined />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* bottom ribbin */}
          <div className="bg-white rounded-br-[25px] rounded-bl-[25px] relative top-[25px] h-[25px]"></div>
          <div className="flex flex-col lgs:flex-row justify-center bg-header-gradient p-3 gap-2 lgs:gap-40 pt-[33px]">
            <div className="font-medium text-[16px] text-white text-center font-outfit">
              Copyright © 2024 JUSTE AI Ltd. All rights reserved.
            </div>
            <div className="flex gap-7 justify-center">
              <span className="font-medium text-[16px] text-white  content-center font-outfit whitespace-break-spaces">
                Terms & Conditions
              </span>
              <span className="font-medium text-[16px] text-white  content-center font-outfit whitespace-break-spaces">
                Cookie Policy
              </span>
              <span className="font-medium text-[16px] text-white  content-center font-outfit whitespace-break-spaces">
                Disclaimer
              </span>
            </div>
          </div>

          {/* Bottom to Top Button */}
          <button
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className={`fixed bottom-6 right-6 w-[35px] h-[35px] text-white bg-[#6721ff] rounded flex justify-center items-center transition-transform ${
              visible
                ? "opacity-100 animate-show-button"
                : "opacity-0 animate-hide-button pointer-events-none"
            }`}
          >
            <DownOutlined className="rotate-180" />
            <span className="scroll-top" />
          </button>
        </div>
      </Formik>
    </div>
  );
};

export default Login;
