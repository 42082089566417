import React, { useEffect, useState } from "react";
import Signin from "./Signin";
import AOS from "aos";
import { baseUrl } from "../services/services.profile";

const list = [
  "Structured AML Compliance Path",
  "Ease of Mind With AML Audit-Ready Health Monitor",
  "Real-Time 24/7 KYC/KYB Information Updates",
  "No Cap of Free of Charge CDD Reports",
  "Efficiency Gains from Reduced Administrative Load",
  "Enhanced Trust With Customers",
];

const Promo = () => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Signin
        modal={modal}
        setModal={setModal}
        isClose={() => setModal(false)}
      />
      <div className="h-screen text-white flex md:flex-row flex-col overflow-y-auto">
        <div className="!min-w-1/2 h-full w-full flex flex-col md:gap-6 gap-8 justify-between items-center lgs:px-12 sm:px-8 px-4 py-8 bg-[#222222] ">
          <div className="flex flex-col md:gap-3 items-center">
            <img
              src="ipass-logo.png"
              className="xl:!h-40 lg:!h-36 !h-32 xl:!w-40 lg:!w-36 !w-32"
              alt=""
              style={{ filter: "brightness(0) invert(1)" }}
            />
            <p className="md:text-5xl text-[34px] font-bold ">JUSTE</p>
          </div>
          <ul className="flex flex-col lg:gap-3 md:gap-2 gap-3 justify-center items-center">
            {list.map((value, index) => (
              <li
                key={index}
                className="font-semibold lg:text-lg  text-[13px] text-center"
              >
                {value}
              </li>
            ))}
          </ul>
          <div className="mt-auto flex flex-col lgs:gap-4 gap-2 items-center justify-center">
            <p className="font-semibold text-center lg:text-xl md:text-lg text-[15px]">
              New to JUSTE?{" "}
              <a
                href=" https://calendly.com/artpass_id_dd/introduction-to-the-platforms-features-and-functionalities"
                target="blank"
                className="!text-white !underline"
              >
                Schedule a Demo{" "}
              </a>
              <span className="underline"></span>
            </p>
            {/* <p className="font-medium underline text-[13px]">
              Terms & Conditions | Privacy Policy | Cookie Policy | Disclaimer
            </p> */}

            <div className="flex justify-center items-center">
              <div className="text-center text-white underline">
                <a
                  href={baseUrl + "/terms/"}
                  target="blank"
                  className="!text-white font-medium underline lg:!text-sm !text-[13px]"
                >
                  Terms & Conditions{" "}
                </a>
                <a
                  href={baseUrl + "/privacy_policy.html"}
                  target="blank"
                  className="!text-white font-medium underline lg:!text-sm !text-[13px]"
                >
                  | Privacy Policy{" "}
                </a>
                <a
                  href={baseUrl + "/cookie_policy.html"}
                  target="blank"
                  className="!text-white font-medium underline lg:!text-sm !text-[13px]"
                >
                  | Cookie Policy{" "}
                </a>
                <a
                  href={baseUrl + "/disclaimer.html"}
                  target="blank"
                  className="!text-white font-medium underline lg:!text-sm !text-[13px]"
                >
                  | Disclaimer{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="!min-w-1/2 w-full h-full md:text-white gap-6 bg-cover bg-center lgs:px-12 sm:px-8 px-4 py-8 flex flex-col justify-between"
          style={{ backgroundImage: "url('/ipass-main-promo-cover.png')" }}
        >
          <p className="xl:text-5xl lg:text-[47px] md:text-[36px] text-3xl font-bold text-center xl:leading-[80px] lg:leading-[65px] sm:leading-[50px] leading-9">
            Spring Season Savings 10%
          </p>
          <div className="flex flex-col xl:gap-12 md:gap-10 mg:gap-6 gap-8 justify-center items-center">
            <div className="flex flex-col md:gap-2 gap-4 justify-center text-center items-center">
              <p className="mb-2 font-bold xl:text-[40px] lg:text-[40px] md:text-3xl text-2xl">
                Start Your Free Trial Today!
              </p>
              <p className="xl:text-[19px] text-[15px] font-semibold">
                Unlimited CDD Reports. 1 Free Inspection Report. Free support
              </p>
              <p className="xl:text-[15px] text-[13px]">
                (The 10% discount offer applies to any paid Basic or Plus
                subscription plan)
              </p>
            </div>
            <button
              onClick={() => setModal(true)}
              className="rounded-lg md:py-3 py-2 px-8 md:w-2/3 w-full bg-[#29BA9C]"
            >
              Sign in
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Promo;
