import axios from "axios";

export const baseUrl = "https://api.juste.ai"; // dev or prod https://artpass.id
export const SITENAME = "iPass ID";
export const SITENAME2 = "JUSTE";

axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");
axios.defaults.withCredentials = true; //dev or prod
// axios.defaults.withCredentials = false   //local

export const forgotPassword = async (email) => {
  const response = await axios.post(baseUrl + "/api/auth/changePassword", {
    email,
  });
  return response.data;
};
export const login = async (email, password) => {
  const response = await axios.post(baseUrl + "/user/login", {
    email,
    password,
  });

  if (response.data.status === 200) {
    localStorage.setItem("token", response.data.token);
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + response.data.token;
  }
  return response.data;
};

export const getProfile = async () => {
  const response = await axios.get(baseUrl + "/api/user/current");
  if (response?.data !== null) {
    localStorage.setItem("userId", response?.data?._id);
  }
  return response.data;
};

export const getAdminProfile = async () => {
  const response = await axios.get(baseUrl + "/api/user/get-admin-data");
  return response.data;
};

export const versionSwitch = async (payload) => {
  const response = await axios.post(
    baseUrl + "/api/user/version-switch",
    payload
  );
  return response.data;
};
